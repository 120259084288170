<template>
  <v-card :dark="$dark.get()">
    <loader v-if="loading"></loader>
    <v-card-title class="header">
      Редактрование главной страницы сайта</v-card-title
    >
    <v-row style="height: max-content">
      <v-col cols="4">
        <div class="text-center">
          <v-chip color="primary" style="font-size: 1.05rem" label>
            Категории
          </v-chip>
        </div>
        <v-card-text>
          <v-autocomplete
            v-model="searchCategory"
            :items="category"
            item-text="name"
            return-object
            hide-details
            label="Добавить категорию"
            @change="addCategory(searchCategory.id)"
          ></v-autocomplete>
          <draggable
            :list="showcaseCategory"
            style="height: 500px; overflow-y: auto"
            group="people"
            class="mt-5"
            @end="changeCategoriesPosition()"
          >
            <v-list-item
              v-for="item in showcaseCategory"
              :key="'pos_' + item.categoryId"
              inactive
            >
              <v-list-item-avatar>
                <v-img v-if="item.categoryImg" :src="item.categoryImg" />
                <v-img
                  v-else
                  src="https://yt3.ggpht.com/a/AGF-l7_ceCzVZPazQqPtML8bWeZenm163-8iUZMxhA=s900-c-k-c0xffffffff-no-rj-mo"
                  width="70px"
                  height="70px"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ item.categoryName }}
              </v-list-item-title>
              <v-list-item-action>
                <v-btn icon @click="loadProducts(item.categoryId)">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="deleteCategoryFromShowcase(item.categoryId)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </draggable>
        </v-card-text>
      </v-col>
      <v-col cols="4">
        <div class="text-center">
          <v-chip color="primary" style="font-size: 1.05rem" label>
            Продукты Категории
          </v-chip>
        </div>
        <v-card-text class="mt-4">
          <v-autocomplete
            v-model="searchProduct"
            :items="categoryAllProducts"
            dense
            item-text="name"
            return-object
            append-outer-icon="mdi-magnify"
            @click:append-outer="addProductToShowcase(searchProduct)"
          ></v-autocomplete>
          <v-list style="height: 600px; overflow-y: auto">
            <v-list-item-group>
              <v-list-item
                v-for="item in categoryAllProducts"
                :key="'prod_list_' + item.id"
                inactive
                two-line
                class="list-item"
                @click="addProductToShowcase(item)"
              >
                <v-list-item-avatar>
                  <v-img
                    :src="
                      item.images
                        ? item.images[0]
                        : 'https://yt3.ggpht.com/a/AGF-l7_ceCzVZPazQqPtML8bWeZenm163-8iUZMxhA=s900-c-k-c0xffffffff-no-rj-mo'
                    "
                    width="70px"
                    height="70px"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ "id: " + item.id }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="hint">
                    добавить на витрину
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-col>
      <v-col cols="4">
        <div class="text-center">
          <v-chip color="primary" style="font-size: 1.05rem" label>
            Продукты Главной Страницы
          </v-chip>
        </div>
        <v-card-text class="mt-12">
          <draggable
            :list="categoryShowcaseProducts"
            style="height: 500px; overflow-y: auto"
            group="people"
            class="mt-5"
            @end="changeCategoryProductsPosition()"
          >
            <v-list-item
              v-for="item in categoryShowcaseProducts"
              :key="'prod_add_list_' + item.id"
              inactive
              two-line
            >
              <v-list-item-avatar>
                <v-img
                  :src="
                    item.images
                      ? item.images[0]
                      : 'https://yt3.ggpht.com/a/AGF-l7_ceCzVZPazQqPtML8bWeZenm163-8iUZMxhA=s900-c-k-c0xffffffff-no-rj-mo'
                  "
                  width="70px"
                  height="70px"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ "id: " + item.id }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="deleteProductFromShowcase(item)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </draggable>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import loader from "../components/Loader";
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
    loader,
  },
  data() {
    return {
      loading: true,
      currCategory: null,
      category: [],
      showcaseCategory: null,
      categoryAllProducts: [],
      categoryShowcaseProducts: [],
      searchProduct: {},
      searchCategory: {},
    };
  },
  computed: {
    ...mapGetters({
      CATEGORY: "Category/STATE",
      PRODUCT: "Products/STATE",
      DEPARTMENT: "Department/STATE",
    }),
  },
  async mounted() {
    this.loading = true;
    if (!this.CATEGORY.allCategory.length > 0) {
      await this.GET_ALL_CATEGORY(0);
    }
    await this.renderPage();
    this.loading = false;
    this.$eventBus.$on("change-city", this.renderPage);
  },
  beforeDestroy() {
    this.$eventBus.$off("change-city");
  },
  methods: {
    ...mapActions({
      GET_ALL_CATEGORY_ON_SHOWCASE: "Category/GET_ALL_CATEGORY_ON_SHOWCASE",
      GET_DEPARTMENT: "Department/GET_DEPARTMENT",
      GET_ALL_CATEGORY: "Category/GET_ALL_CATEGORY",
      DELETE_CATEGORY_ON_SHOWCASE: "Category/DELETE_CATEGORY_ON_SHOWCASE",
      GET_PRODUCTS_OF_SHOWCASE_CATEGORY:
        "Products/GET_PRODUCTS_OF_SHOWCASE_CATEGORY",
      ADD_CATEGORY_ON_SHOWCASE: "Category/ADD_CATEGORY_ON_SHOWCASE",
      GET_CATEGORY_PRODUCTS: "Products/GET_CATEGORY_PRODUCTS",
      ADD_PRODUCT_TO_SHOWCASE: "Products/ADD_PRODUCT_TO_SHOWCASE",
      DELETE_PRODUCT_FROM_SHOWCASE: "Products/DELETE_PRODUCT_FROM_SHOWCASE",
      CHANGE_SHOWCASE_CATEGORIES_ORDER:
        "Category/CHANGE_SHOWCASE_CATEGORIES_ORDER",
      CHANGE_SHOWCASE_CATEGORY_PRODUCTS_ORDER:
        "Products/CHANGE_SHOWCASE_CATEGORY_PRODUCTS_ORDER",
    }),

    checkPower() {
      let state = this.$power.check(this.$route.meta.power);
      if (state === false) {
        this.$router.push("/");
      }
    },

    async renderPage() {
      this.loading = true;
      this.checkPower();
      await this.GET_DEPARTMENT(this.$root.city);
      this.showcaseCategory = await this.GET_ALL_CATEGORY_ON_SHOWCASE();
      this.category = this.CATEGORY.allCategory;
      this.loading = false;
    },

    // при нажатии на плюс у категории выводит все продукты категории: доступные ей и те что выводятся на витрине
    async loadProducts(categoryId) {
      this.currCategory = categoryId;
      // ВСЕ продукты которые есть у категории:
      const showcaseCategoryAllProducts = await this.GET_CATEGORY_PRODUCTS(
        categoryId
      );
      if (!showcaseCategoryAllProducts.type) {
        this.categoryAllProducts = showcaseCategoryAllProducts;
      } else {
        this.categoryAllProducts = [];
      }
      // продукты которые отображаются ТОЛЬКО на витрине:
      const showcaseCategoryProducts = await this.GET_PRODUCTS_OF_SHOWCASE_CATEGORY(
        categoryId
      );
      if (showcaseCategoryProducts.type !== "error") {
        this.categoryShowcaseProducts = showcaseCategoryProducts;
      } else {
        this.categoryShowcaseProducts = [];
      }
    },

    async addCategory(categoryId) {
      this.loading = true;
      const response = await this.ADD_CATEGORY_ON_SHOWCASE(categoryId);
      if (!response) {
        await this.renderPage();
        await this.loadProducts(this.currCategory);
      }
      this.loading = false;
    },

    async deleteCategoryFromShowcase(categoryId) {
      this.loading = true;
      const response = await this.DELETE_CATEGORY_ON_SHOWCASE(categoryId);
      if (!response) {
        await this.renderPage();
      }
      this.loading = false;
    },

    async addProductToShowcase(product) {
      if (!product.id) {
        return;
      }
      this.loading = true;
      const response = await this.ADD_PRODUCT_TO_SHOWCASE({
        productId: product.id,
        categoryId: this.currCategory,
      });
      if (response.type !== "error") {
        await this.renderPage();
        await this.loadProducts(this.currCategory);
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.data.message,
        });
      }
      this.searchProduct = {};
      this.loading = false;
    },

    async deleteProductFromShowcase(product) {
      this.loading = true;
      const response = await this.DELETE_PRODUCT_FROM_SHOWCASE({
        productId: product.id,
        categoryId: this.currCategory,
      });
      if (!response) {
        await this.renderPage();
        await this.loadProducts(this.currCategory);
      }
      this.loading = false;
    },

    async changeCategoriesPosition() {
      this.loading = true;
      const newOrder = this.showcaseCategory.map((item, index) => {
        const { categoryId } = item;
        return {
          id: categoryId,
          position: index + 1,
        };
      });
      this.showcaseCategory = await this.CHANGE_SHOWCASE_CATEGORIES_ORDER(
        newOrder
      );
      this.loading = false;
    },

    async changeCategoryProductsPosition() {
      this.loading = true;
      const newOrder = this.categoryShowcaseProducts.map((item, index) => ({
        id: item.id,
        position: index + 1,
      }));
      this.categoryShowcaseProducts = await this.CHANGE_SHOWCASE_CATEGORY_PRODUCTS_ORDER(
        {
          categoryId: this.currCategory,
          body: newOrder,
        }
      );
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.hint {
  display: none;
}
.list-item {
  border-radius: 10px;

  &:hover {
    cursor: pointer;
    background-color: antiquewhite;
    .hint {
      display: block;
    }
  }
}
</style>
